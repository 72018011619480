<template>
    <div class="make">
        <div class="make1">
            <div class="title">
                <span class="num">
                    第1/1题
                </span>
                <span class="name">每日一练</span>
            </div>
            <div class="cn">
                {{info.title}}
            </div>
            <div class="cn1" v-show="info.titleImage">
                <div class="cnImg">
                    <ImageShow :src="info.titleImage" class="tiImg" />    
                </div>
            </div>
        </div>

        <div class="tiankong" v-show="info.type == 1">
           <div class="intro">
               <span>选择：</span>
               <span></span>
           </div>
           <div class="tian-btn"
                v-for="(it, index) in info.requestOption"
                :key="index"
                @click="select = index"
                :class="select ==index && 'active'"
            >
               {{selectList[index]}}选项： {{it}}
           </div>
        </div>

       <div class="tiankong" v-show="info.type == 2">
           <div class="intro">
               <span>填空：</span>
               <span class="look"></span>
           </div>
           <div class="tian-input" 
                v-for="(it, index) in info.answer"
                :key="index"

            >
               <input :placeholder="`第${index + 1}空`" v-model="tianList[index]"/>
           </div>
           <!-- <div class="tian-input">
               <input placeholder="第2空" />
           </div> -->
       </div>

        <div class="clickBtn">
            <div class="left">
                <!-- <div class="one">
                    下一题
                </div> -->
                <div class="two">
                    <p class="last" @click="submit">提交</p>
                    <p class="next" @click="shop">积分商城</p>
                </div>
            </div>
        </div>

        <!-- <div>
            <div class="bg" v-show="look" />
            <LookDetail :info="list[0]" v-show="look" />
        </div> -->
       <Tips :tip="msg"/>
    </div>
</template>

<script>
import ImageShow from '@/components/ImageShow';
import LookDetail from '@/components/LookDetail';
import { formatDate, getQuery } from '../../common/util';
export default {
    components: {
        ImageShow,
        LookDetail
    },
    data () {
        return {
            look: false,
            curr: 1,
            list: [],
            id: '',
            select: -1,
            selectList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            info: {
                activityId: 1,
                answer: "",
                answerContent: "",
                answerImage: "",
                id: '',
                integral: '',       
                requestOption: [],
                title: "",
                titleImage: "",
                type: 1, // 1-选则题， 2-填空题
            },
            // 填空
            tianList: [],
            msg: ''
        }
    },
    mounted() {
        this.id = getQuery('id');
        this.getData();
    },
    methods: {
        getInfo() {
            this.$http.get(`/api/user/getInfo`, {
            })
            .then((res) => {
                localStorage.setItem('ng-data', JSON.stringify(res.data.data));
                this.info = res.data.data;
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        },
        submit() {
            let isCorrect = 1;
            // 选择题
            if (this.info.type === 1) {
                if (this.select === -1) {
                    alert('请选择答案');
                    return;
                };
                if (this.info.requestOption[this.select] === this.info.answer[0]) {
                    isCorrect = 1;
                } else {
                    isCorrect = 0;
                }
            } else {
                if (!this.tianList[0]) {
                    alert('请输入答案');
                    return;
                };
                this.tianList.forEach((it, index) => {
                    if (it?.trim?.() != this.info.answer[index]) {
                        isCorrect = 0;
                    }
                })
            }
            this.$http.post(`/api/activity/answerRequest`, {
                activityId: this.id,
                activityRequestId: this.info.id,
                isCorrect: isCorrect,
            })
            .then((res) => {
                if (isCorrect) {
                    this.msg = '恭喜，本次答题正确';
                } else {
                    this.msg = '很遗憾，本次答题错误';
                }
                this.getInfo();
                setTimeout(() => {
                    this.$router.go(-1);
                }, 1000)
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        },
        shop() {
            this.$router.push('/scoreShop')
        },
        getData() {
            this.$http.get(`/api/activity/${this.id}/todayRequest`, {
            })
            .then((res) => {
                let info = res.data.data[0];
                if (!res.data.data[0]) {
                    alert('今日无答题')
                }
                info.requestOption = JSON.parse(info.requestOption);
                info.answer = JSON.parse(info.answer);
                this.info = info;
                
            }).catch((err) => {
                this.validErrorInfo = err.errorMsg;
            })
        },
    }
}
</script>

<style lang="less" scoped>
    .make {
        min-height: 100vh;
        background-color: #f4f6f6;
        position: relative;
        z-index: 0;
        .make1 {
            background-color: white;
            padding-bottom: 0.35rem;
        }
        .title {
            padding: 0 0.3rem;
            box-sizing: border-box;
            height: 0.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            .num {
                font-size: 0.28rem;
                background-color: #79cdca;
                padding: 0.05rem 0.15rem;
                color: white;
                border-radius: 0.12rem;
            }
            .name {
                font-size: 0.25rem;
                color: #666666;
            }
        }
        .cn {
            padding: 0.2rem 0.3rem 0;
            box-sizing: border-box;
            font-size: 0.35rem;
            color: #333333;
        }
        .cn1 {
            padding: 0 0.3rem;
            box-sizing: border-box;
            margin-top: 0.35rem;
            .cnImg {
                padding: 0.3rem;
                box-sizing: border-box;
                box-shadow: 0px 2px 7px 0px rgba(27, 27, 78, 0.1);
                border-radius: 0.15rem;
                .tiImg {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    height: auto;
                }
            }
        }
        .tiankong {
            margin-top: 0.35rem;
            padding: 0 0.3rem;
            box-sizing: border-box;
            padding-bottom: 2.6rem;
            .intro {
                display: flex;
                justify-content: space-between;
                font-size: 0.28rem;
                color: #999;
                .look {
                    font-size: 0.32rem;
                }
            }
            .tian-btn {
                &.active {
                    background-color: #79cdca;
                    color: white;
                }
                width: 4.5rem;
                height: 1rem;
                line-height: 1rem;
                background-color: #e1f0f0;
                display: block;
                border-radius: 1rem;
                margin: 0.45rem auto 0;
                text-align: center;
                font-size: 0.35rem;
                color: #999;
            }
            .tian-input {
                width: 4.5rem;
                height: 1rem;
                background-color: #e1f0f0;
                display: block;
                border-radius: 1rem;
                margin: 0.45rem auto 0;
                display: flex;
                align-items: center;
                overflow: hidden;
                input {
                    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                        text-align: center;
                    }
                    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                        text-align: center;
                    }
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        text-align: center;
                    }
                    background-color:#e1f0f0;
                    height: 0.85rem;
                    width: 95%;
                    display: block;
                    font-size: 0.35rem;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }
        .clickBtn {
            position: absolute;
            left: 0;
            // left: 50%;
            // transform: translateX(-50%);
            bottom: 0.5rem;
            padding: 0 0.3rem;
            box-sizing: border-box;
            width: 100%;
            max-width: 7.5rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .left {
                .one {
                    width: 5rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    border-radius: 1rem;
                    background-color: #79cdca;
                    text-align: center;
                    font-size: 0.36rem;
                    color: white;
                }
                .two {
                    text-align: center;
                    font-size: 0.36rem;
                    width: 6rem;
                    .last {
                        width: 3rem;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        border-radius: 1rem;
                        background-color: white;
                        color: #79cdca;
                        display: inline-block;
                        margin-right: 0.3rem;
                        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
                    }
                    .next {
                        width: 2.2rem;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        border-radius: 1rem;
                        background-color: #79cdca;
                        color: white;
                        display: inline-block;
                    }
                }
            }
            .right {
                width: 1.5rem;
                height: 1.5rem;
                background-color: #9cdad6;
                border: 1px solid #79cdca;
                border-radius: 0.12rem;
                .r-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 0.32rem;
                    margin-top: 0.08rem;
                    img {
                        width: 0.6rem;
                        height: 0.6rem;
                        margin-right: 0.06rem;
                    }
                }
            }
        }
        .rigthDialog {
            position: absolute;
            top: 0;
            height: 100%;
            right: 0;
            width: 3rem;
            background-color: #f4f6f6;
            z-index: 10;
            .list {
                height: 10rem;
                overflow-y: scroll;
                .item {
                    &.active {
                        background-color:  #e1f0f0;
                    }
                    height: 1rem;
                    border-bottom: 1px solid #d9d9d9;
                    padding: 0 0.3rem;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 0.32rem;
                    color: #333333;
                    .result {
                        &.error {
                            color: #CA0E2D;
                        }
                        color:  #79cdca;
                    }
                }
            }
            .bottom {
                padding: 0 0.3rem;
                box-sizing: border-box;
                .time {
                    font-size: 0.3rem;
                    margin-top: 0.25rem;
                }
                .againBtn {
                    margin-top: 0.25rem;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    text-align: center;
                    width: 100%;
                    border-radius: 1rem;
                    font-size: 0.32rem;
                    color: white;
                    background-image: linear-gradient(to right, #f0bf5a, #e8343a);
                }
                .backBtn {
                    margin-top: 0.25rem;
                    background-color: #79cdca;
                    height: 0.8rem;
                    line-height: 0.8rem;
                    text-align: center;
                    width: 100%;
                    border-radius: 1rem;
                    font-size: 0.32rem;
                    color: white;
                }
            }
        }
    }
</style>
